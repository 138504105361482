import { routeLinks } from 'routes/config';

const breadcrumbs = [
  {
    title: 'หน้าหลัก',
    children: [
      {
        title: 'แดชบอร์ด',
        url: routeLinks.home
      }
    ]
  },
  {
    title: 'ผู้ใช้งาน',
    url: routeLinks.users.list,
    children: [
      {
        title: 'เพิ่ม',
        url: routeLinks.users.create
      },
      {
        title: 'แก้ไข',
        url: routeLinks.users.edit
      },
      {
        title: 'รายละเอียด',
        url: routeLinks.users.view
      },
      {
        title: 'รีเซ็ตรหัสผ่าน',
        url: routeLinks.users.resetPassword
      }
    ]
  },
  {
    title: 'ประเทศ',
    url: routeLinks.countries.list,
    children: [
      {
        title: 'เพิ่ม',
        url: routeLinks.countries.create
      },
      {
        title: 'แก้ไข',
        url: routeLinks.countries.edit
      },
      {
        title: 'รายละเอียด',
        url: routeLinks.countries.view
      }
    ]
  },
  {
    title: 'สัญชาติ',
    url: routeLinks.nationalities.list,
    children: [
      {
        title: 'เพิ่ม',
        url: routeLinks.nationalities.create
      },
      {
        title: 'แก้ไข',
        url: routeLinks.nationalities.edit
      },
      {
        title: 'รายละเอียด',
        url: routeLinks.nationalities.view
      }
    ]
  },
  {
    title: 'ฤดูกาล',
    url: routeLinks.seasons.list,
    children: [
      {
        title: 'เพิ่ม',
        url: routeLinks.seasons.create
      },
      {
        title: 'แก้ไข',
        url: routeLinks.seasons.edit
      },
      {
        title: 'รายละเอียด',
        url: routeLinks.seasons.view
      }
    ]
  },
  {
    title: 'ลีก',
    url: routeLinks.leagues.list,
    children: [
      {
        title: 'เพิ่ม',
        url: routeLinks.leagues.create
      },
      {
        title: 'แก้ไข',
        url: routeLinks.leagues.edit
      },
      {
        title: 'รายละเอียด',
        url: routeLinks.leagues.view
      }
    ]
  },
  {
    title: 'ทีม',
    url: routeLinks.teams.list,
    children: [
      {
        title: 'เพิ่ม',
        url: routeLinks.teams.create
      },
      {
        title: 'แก้ไข',
        url: routeLinks.teams.edit
      },
      {
        title: 'รายละเอียด',
        url: routeLinks.teams.view
      }
    ]
  },
  {
    title: 'นักเตะ',
    url: routeLinks.players.list,
    children: [
      {
        title: 'เพิ่ม',
        url: routeLinks.players.create
      },
      {
        title: 'แก้ไข',
        url: routeLinks.players.edit
      },
      {
        title: 'รายละเอียด',
        url: routeLinks.players.view
      }
    ]
  },
  {
    title: 'สนามฟุตบอล',
    url: routeLinks.stadiums.list,
    children: [
      {
        title: 'เพิ่ม',
        url: routeLinks.stadiums.create
      },
      {
        title: 'แก้ไข',
        url: routeLinks.stadiums.edit
      },
      {
        title: 'รายละเอียด',
        url: routeLinks.stadiums.view
      }
    ]
  },
  {
    title: 'แมตช์',
    url: routeLinks.matches.list,
    children: [
      {
        title: 'เพิ่ม',
        url: routeLinks.matches.create
      },
      {
        title: 'แก้ไข',
        url: routeLinks.matches.edit
      },
      {
        title: 'บทวิเคราะห์',
        url: routeLinks.matches.articleEdit
      }
    ]
  },
  {
    title: 'จัดการราคา',
    children: [
      {
        title: 'Providers',
        url: routeLinks.bookmakers.list,
        children: [
          {
            title: 'เพิ่ม',
            url: routeLinks.bookmakers.create
          },
          {
            title: 'แก้ไข',
            url: routeLinks.bookmakers.edit
          },
          {
            title: 'รายละเอียด',
            url: routeLinks.bookmakers.view
          }
        ]
      },
      {
        title: 'ราคา',
        url: routeLinks.odds.list,
        children: [
          {
            title: 'เพิ่ม',
            url: routeLinks.odds.create
          },
          {
            title: 'แก้ไข',
            url: routeLinks.odds.edit
          },
          {
            title: 'รายละเอียด',
            url: routeLinks.odds.view
          }
        ]
      }
    ]
  },
  {
    title: 'ตั้งค่า',
    children: [
      {
        title: 'การสดงผล',
        url: routeLinks.settings.sites.list,
        children: [
          {
            title: 'เพิ่ม',
            url: routeLinks.settings.sites.create
          },
          {
            title: 'แก้ไข',
            url: routeLinks.settings.sites.edit
          },
          {
            title: 'รายละเอียด',
            url: routeLinks.settings.sites.view
          }
        ]
      }
    ]
  },
  {
    title: 'Monitors',
    children: [
      {
        title: 'Whoscored',
        url: routeLinks.whoscored.tasks.monitors.list,
        children: [
          {
            title: 'รายละเอียด',
            url: routeLinks.whoscored.tasks.monitors.view
          }
        ]
      },
      {
        title: 'DataMB',
        url: routeLinks.datamb.tasks.monitors.list,
        children: [
          {
            title: 'รายละเอียด',
            url: routeLinks.datamb.tasks.monitors.view
          }
        ]
      }
    ]
  },
  {
    title: 'Proxies',
    children: [
      {
        title: 'Whoscored',
        url: routeLinks.whoscored.workers.proxies.list,
        children: [
          {
            title: 'แก้ไข',
            url: routeLinks.whoscored.workers.proxies.edit
          },
          {
            title: 'รายละเอียด',
            url: routeLinks.whoscored.workers.proxies.view
          }
        ]
      }
    ]
  }
];

export default breadcrumbs;
