export const routeLinks = {
  auth: {
    login: '/login'
  },
  error: {
    error404: '/404'
  },
  home: '/dashboard',
  profile: {
    changepassword: '/profile/password/change',
    info: '/profile/info'
  },
  users: {
    list: '/users',
    view: '/users/:id',
    create: '/users/create',
    edit: '/users/:id/edit',
    resetPassword: '/users/:id/password/reset'
  },
  leagues: {
    list: '/leagues',
    view: '/leagues/:id',
    create: '/leagues/create',
    edit: '/leagues/:id/edit'
  },
  seasons: {
    list: '/seasons',
    view: '/seasons/:id',
    create: '/seasons/create',
    edit: '/seasons/:id/edit'
  },
  teams: {
    list: '/teams',
    view: '/teams/:id',
    create: '/teams/create',
    edit: '/teams/:id/edit'
  },
  players: {
    list: '/players',
    view: '/players/:id',
    create: '/players/create',
    edit: '/players/:id/edit'
  },
  countries: {
    list: '/countries',
    view: '/countries/:id',
    create: '/countries/create',
    edit: '/countries/:id/edit'
  },
  nationalities: {
    list: '/nationalities',
    view: '/nationalities/:id',
    create: '/nationalities/create',
    edit: '/nationalities/:id/edit'
  },
  stadiums: {
    list: '/stadiums',
    view: '/stadiums/:id',
    create: '/stadiums/create',
    edit: '/stadiums/:id/edit'
  },
  matches: {
    list: '/matches',
    view: '/matches/:id',
    create: '/matches/create',
    edit: '/matches/:id/edit',
    article: '/matches/:id/article',
    articleEdit: '/matches/:id/article/edit',
    headToHead: '/matches/:id/head-to-head',
    team: '/matches/:id/team/:team',
    odds: '/matches/:id/odds',
    keyman: '/matches/:id/team/:team/keyman'
  },
  lineups: {
    list: '/lineups',
    view: '/lineups/:id',
    create: '/lineups/create',
    edit: '/lineups/:id/edit'
  },
  teamStandings: {
    list: '/team/standings',
    view: '/teams/standings/:id',
    create: '/teams/standings/create',
    edit: '/teams/standings/:id/edit'
  },
  articles: {
    list: '/articles',
    view: '/articles/:id',
    create: '/articles/create',
    edit: '/articles/:id/edit'
  },
  bookmakers: {
    list: '/bookmakers',
    view: '/bookmakers/:id',
    create: '/bookmakers/create',
    edit: '/bookmakers/:id/edit'
  },
  odds: {
    list: '/odds',
    view: '/odds/:id',
    create: '/odds/create',
    edit: '/odds/:id/edit'
  },
  settings: {
    sites: {
      list: '/settings/sites/',
      view: '/settings/sites/:id',
      create: '/settings/sites/create',
      edit: '/settings/sites/:id/edit'
    }
  },
  whoscored: {
    tasks: {
      monitors: {
        list: '/whoscored/tasks/monitors',
        view: '/whoscored/tasks/monitors/:id'
      }
    },
    workers: {
      proxies: {
        list: '/whoscored/workers/proxies',
        view: '/whoscored/workers/proxies/:id',
        create: '/whoscored/workers/proxies/create',
        edit: '/whoscored/workers/proxies/:id/edit'
      }
    }
  },
  datamb: {
    tasks: {
      monitors: {
        list: '/datamb/tasks/monitors',
        view: '/datamb/tasks/monitors/:id'
      }
    }
  }
};

export const apiUrls = {
  v1: {
    languages: '/v1/admin/languages/available/',
    user: {
      info: '/v1/admin/user/',
      login: '/v1/admin/user/auth/',
      logout: '/v1/admin/user/logout/',
      changePassword: '/v1/admin/user/password/change/',
      permissions: '/v1/admin/user/permissions/'
    },
    users: {
      list: '/v1/admin/users/',
      view: '/v1/admin/users/:id/',
      create: '/v1/admin/users/',
      edit: '/v1/users/:id/',
      resetPassword: '/v1/admin/users/:id/password/reset/',
      resetToken: '/v1/admin/users/:id/token/reset/'
    },
    leagues: {
      list: '/v1/admin/football/leagues/',
      view: '/v1/admin/football/leagues/:id/',
      create: '/v1/admin/football/leagues/',
      update: '/v1/admin/football/leagues/:id/',
      delete: '/v1/admin/football/leagues/:id/'
    },
    seasons: {
      list: '/v1/admin/football/seasons/',
      view: '/v1/admin/football/seasons/:id/',
      create: '/v1/admin/football/seasons/',
      update: '/v1/admin/football/seasons/:id/',
      delete: '/v1/admin/football/seasons/:id/'
    },
    teams: {
      list: '/v1/admin/football/teams/',
      view: '/v1/admin/football/teams/:id/',
      create: '/v1/admin/football/teams/',
      update: '/v1/admin/football/teams/:id/',
      delete: '/v1/admin/football/teams/:id/',
      returns: {
        list: '/v1/admin/football/teams/returns/',
        view: '/v1/admin/football/teams/returns/:id/',
        create: '/v1/admin/football/teams/returns/',
        update: '/v1/admin/football/teams/returns/:id/',
        delete: '/v1/admin/football/teams/returns/:id/'
      }
    },
    players: {
      list: '/v1/admin/football/players/',
      view: '/v1/admin/football/players/:id/',
      create: '/v1/admin/football/players/',
      update: '/v1/admin/football/players/:id/',
      delete: '/v1/admin/football/players/:id/',
      potisions: {
        zones: '/v1/admin/football/players/positions/zones/'
      },
      stats: {
        list: '/v1/admin/football/players/stats/',
        view: '/v1/admin/football/players/stats/:id/',
        create: '/v1/admin/football/players/stats/',
        update: '/v1/admin/football/players/stats/:id/'
      }
    },
    countries: {
      list: '/v1/admin/football/countries/',
      view: '/v1/admin/football/countries/:id/',
      create: '/v1/admin/football/countries/',
      update: '/v1/admin/football/countries/:id/',
      delete: '/v1/admin/football/countries/:id/'
    },
    nationalities: {
      list: '/v1/admin/football/nationalities/',
      view: '/v1/admin/football/nationalities/:id/',
      create: '/v1/admin/football/nationalities/',
      update: '/v1/admin/football/nationalities/:id/',
      delete: '/v1/admin/football/nationalities/:id/'
    },
    stadiums: {
      list: '/v1/admin/football/stadiums/',
      view: '/v1/admin/football/stadiums/:id/',
      create: '/v1/admin/football/stadiums/',
      update: '/v1/admin/football/stadiums/:id/',
      delete: '/v1/admin/football/stadiums/:id/'
    },
    matches: {
      list: '/v1/admin/football/matches/',
      view: '/v1/admin/football/matches/:id/',
      create: '/v1/admin/football/matches/',
      update: '/v1/admin/football/matches/:id/',
      delete: '/v1/admin/football/matches/:id/',
      teams: {
        returns: '/v1/admin/football/matches/:match_id/teams/:team_id/returns/'
      }
    },
    lineups: {
      list: '/v1/admin/football/lineups/',
      view: '/v1/admin/football/lineups/:id/',
      create: '/v1/admin/football/lineups/',
      update: '/v1/admin/football/lineups/:id/',
      delete: '/v1/admin/football/lineups/:id/'
    },
    lineupPlayers: {
      list: '/v1/admin/football/lineups/players/',
      view: '/v1/admin/football/lineups/players/:id/',
      create: '/v1/admin/football/lineups/players/',
      update: '/v1/admin/football/lineups/players/:id/',
      delete: '/v1/admin/football/lineups/players/:id/',
      team: '/v1/admin/football/matches/:match_id/teams/:team_id/lineup/players/'
    },
    teamStats: {
      list: '/v1/admin/football/teams/stats/',
      view: '/v1/admin/football/teams/stats/:id/',
      create: '/v1/admin/football/teams/stats/',
      update: '/v1/admin/football/teams/stats/:id/',
      delete: '/v1/admin/football/teams/stats/:id/'
    },
    teamStandings: {
      list: '/v1/admin/football/teams/standings/',
      view: '/v1/admin/football/teams/standings/:id/',
      create: '/v1/admin/football/teams/standings/',
      update: '/v1/admin/football/teams/standings/:id/',
      delete: '/v1/admin/football/teams/standings/:id/'
    },
    articles: {
      list: '/v1/admin/football/articles/',
      view: '/v1/admin/football/articles/:id/',
      create: '/v1/admin/football/articles/',
      update: '/v1/admin/football/articles/:id/',
      delete: '/v1/admin/football/articles/:id/'
    },
    teamLatestMatch: {
      list: '/v1/admin/football/teams/latest-matches/',
      view: '/v1/admin/football/teams/latest-matches/:id/',
      create: '/v1/admin/football/teams/latest-matches/',
      update: '/v1/admin/football/teams/latest-matches/:id/',
      delete: '/v1/admin/football/teams/latest-matches/:id/'
    },
    headToHead: {
      list: '/v1/admin/football/head-to-head/',
      view: '/v1/admin/football/head-to-head/:id/',
      create: '/v1/admin/football/head-to-head/',
      update: '/v1/admin/football/head-to-head/:id/',
      delete: '/v1/admin/football/head-to-head/:id/'
    },
    bookmakers: {
      list: '/v1/admin/football/bookmakers/',
      view: '/v1/admin/football/bookmakers/:id/',
      create: '/v1/admin/football/bookmakers/',
      update: '/v1/admin/football/bookmakers/:id/',
      delete: '/v1/admin/football/bookmakers/:id/'
    },
    odds: {
      list: '/v1/admin/football/odds/',
      view: '/v1/admin/football/odds/:id/',
      create: '/v1/admin/football/odds/',
      update: '/v1/admin/football/odds/:id/',
      delete: '/v1/admin/football/odds/:id/'
    },
    settings: {
      sites: {
        list: '/v1/admin/football/settings/sites/',
        view: '/v1/admin/football/settings/sites/:id/',
        create: '/v1/admin/football/settings/sites/',
        update: '/v1/admin/football/settings/sites/:id/',
        delete: '/v1/admin/football/settings/sites/:id/'
      }
    },
    whoscored: {
      tasks: {
        monitors: {
          list: '/v1/admin/whoscored/tasks/monitors/',
          view: '/v1/admin/whoscored/tasks/monitors/:id',
          categories: '/v1/admin/whoscored/tasks/monitors/categories/',
          types: '/v1/admin/whoscored/tasks/monitors/types/'
        }
      },
      workers: {
        proxies: {
          list: '/v1/admin/whoscored/workers/proxies/',
          view: '/v1/admin/whoscored/workers/proxies/:id/',
          create: '/v1/admin/whoscored/workers/proxies/',
          update: '/v1/admin/whoscored/workers/proxies/:id/',
          delete: '/v1/admin/whoscored/workers/proxies/:id/'
        }
      }
    },
    datamb: {
      tasks: {
        monitors: {
          list: '/v1/admin/datamb/tasks/monitors/',
          view: '/v1/admin/datamb/tasks/monitors/:id/',
          categories: '/v1/admin/datamb/tasks/monitors/categories/',
          types: '/v1/admin/datamb/tasks/monitors/types/'
        }
      }
    }
  }
};
