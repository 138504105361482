import { Chip } from '@mui/material';
import { STATUS_ENUM } from 'config/constants';
import React from 'react';

interface Props {
  value: string;
}

const Colors: any = {
  [`${STATUS_ENUM.DRAFT}`]: 'default',
  [`${STATUS_ENUM.PUBLIC}`]: 'success',
  [`${STATUS_ENUM.PRIVATE}`]: 'error',
  FAILED: 'error',
  SUCCESS: 'success',
  NOT_USE: 'default',
  USING: 'success',
  BLOCKED: 'default'
};

const StatusLabel: React.FC<Props> = ({ value }) => {
  return <Chip label={value} color={Colors[value] ?? 'default'} size="small" className="text-xs" />;
};

export default StatusLabel;
